import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Flex from '../Flex/Flex';
import Link from '../Link/Link';
import Image from '../Image/Image';

import { colors, weights } from '../../style';

const HamburgerMenuLink = ({ children, linkTo, onClick, hasBorderBottom }) => (
  <Link
    height="60px"
    width="100%"
    color={colors.white}
    display="flex"
    alignItems="center"
    borderTop={`1px solid ${colors.white}`}
    borderBottom={hasBorderBottom && `1px solid ${colors.white}`}
    paddingLeft="30px"
    fontWeight={weights.bold}
    onClick={onClick}
  >
    <AnchorLink href={linkTo} style={{ color: 'inherit', textDecoration: 'none' }}>
      {children}
    </AnchorLink>
  </Link>
);

const HamburgerMenu = ({ onLinkClick, ...props }) => (
  <Flex
    height="100vh"
    width="100vw"
    backgroundColor={colors.darkBlue}
    position="absolute"
    top="0"
    left="0"
    flexDirection="column"
    paddingTop="100px"
    overflowX="hidden"
  >
    <HamburgerMenuLink linkTo="#whatIs" onClick={() => onLinkClick('whatIs')}>
      {props.about}
    </HamburgerMenuLink>
    <HamburgerMenuLink linkTo="#steps" onClick={() => onLinkClick('steps')}>
      {props.steps}
    </HamburgerMenuLink>
    <HamburgerMenuLink linkTo="#requirements" onClick={() => onLinkClick('requirements')}>
      {props.requirements}
    </HamburgerMenuLink>
    <HamburgerMenuLink linkTo="#faqs" onClick={() => onLinkClick('faqs')} hasBorderBottom>
      {props.faq}
    </HamburgerMenuLink>
    <Flex
      width="100%"
      backgroundImage="url('/img/bg-wing@1x.png')"
      backgroundSize="400px"
      backgroundRepeat="no-repeat"
      minHeight="200px"
      justifyContent="center"
      alignItems="center"
    >
      <Image
        src={props.srcLogo}
        width="200px"
        height="110px"
      />
    </Flex>
  </Flex>
);

export default HamburgerMenu;