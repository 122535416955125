module.exports = 
{
  menu_links: {
    about: 'Sobre Nosotros',
    steps: 'Pasos',
    requirements: 'Requerimientos',
    faq: 'Preguntas Frecuentes'
  },
  lang: {
    en: 'Inglés',
    es: 'Español'
  },
  hero: {
    title: 'Su Licencia Para Volar',
    main_description: 'Si usted necesita viajar, debe hacer un viaje a su oficina local de la división de vehículos motorizados MVD o a un proveedor externo autorizado para obtener la nueva identificación de Arizona para viajar “AZ Travel ID”.',
    secundary_description: 'La identificación para viajar AZ Travel ID es parte de un cambio de seguridad a nivel nacional de la administración TSA, el cual entrará en vigor el 7 de Mayo de 2025, y que requiere una identificación para viajar AZ Travel ID u otra forma de identificación aprobada para viajar dentro del país.',
    button: 'Obtenga su identificación para viajar AZ Travel ID'
  },
  about: {
    title: '¿Qué es una identificación para viajar AZ Travel ID?',
    copy: 'La identificación para viajar AZ Travel ID con una estrella dorada incrustada, es la credencial que agrega un comprobante de identificación para asegurar que la licencia o identificación cumpla con los estrictos requerimientos federales. Está disponible como licencia de manejo y como tarjeta de identificación. Esta nueva identificación cumple con el Decreto Federal de IDENTIFICACIÓN REAL de 2005, el cual cambió los requerimientos necesarios para los viajes domésticos por avión o para entrar a una instalación federal segura. Las reglas para los viajes internacionales no han cambiado, y se requerirá un pasaporte válido o su equivalente para viajar fuera de los Estados Unidos.',
    link: 'Aprenda más sobre el Decreto de IDENTIFICACIÓN REAL'
  },
  steps: {
    title: '¿Cómo obtener su identificación para viajar AZ Travel ID?',
    or: 'Ó',
    step_one: 'Haga su solicitud',
    step_two: 'Programe Una Cita con la División MVD',
    step_three: 'Vea Proveedores Externos',
    step_covid: 'Contacte con el MVD al 602.712.2700'
  },
  requirements: {
    title: 'Requerimientos de Documentación para la Identificación para Viajar AZ Travel ID',
    copy: 'Cuando solicite su identificación para viajar AZ Travel ID, usted debe llevar documentos de cada una de estas categorías de identificación. Todos los documentos deben ser originales en inglés o certificados por la agencia emisora.',
    choose_one: 'Elija uno de estos:',
    choose_two: 'Elija dos de estos:',
    card_title_1: 'Comprobante de Identidad',
    card_features_1: [
      'Certificado de Nacimiento',
      'Pasaporte de los Estados Unidos',
      'Tarjeta de Pasaporte'
    ],
    card_title_2: 'Número de Seguro Social',
    card_features_2: [
      'Tarjeta del Seguro Social',
      'Forma W-2'
    ],
    card_title_3: 'Comprobante de Residencia en Arizona',
    card_features_3: [
      'Facturas de los Servicios Públicos',
      'Estado de Cuenta de la Tarjeta de Crédito',
      'Estado de Cuenta Bancario',
      'Póliza de Seguros'
    ]
  },
  faqs: {
    title: 'Preguntas Frecuentes',
    content: [
      {
        key: 1,
        qn: '¿Se requiere que yo obtenga una identificación para viajar AZ Travel ID?',
        ans: 'No. Los residentes de Arizona tienen la opción de comprar una identificación para viajar.'
      },
      {
        key: 2,
        qn: '¿Puedo usar mi licencia de manejo o tarjeta de identificación actual de Arizona para abordar un avión?',
        ans: 'Sí. El Departamento de Seguridad Nacional de los Estados Unidos ha dicho que las identificaciones de los estados que ofrecen credenciales que cumplan con el Decreto Federal de IDENTIFICACIÓN REAL serán aceptadas hasta el 7 de Mayo de 2025.'
      },
      {
        key: 3,
        qn: '¿Puedo usar mi licencia de manejo o tarjeta de identificación actual de Arizona para entrar a edificios federales restringidos y bases militares?',
        ans: 'Sí. El Departamento de Seguridad Nacional de los Estados Unidos ha dicho que las identificaciones de los estados que ofrecen credenciales que cumplan con el Decreto Federal de IDENTIFICACIÓN REAL serán aceptadas hasta el 7 de Mayo de 2025.'
      },
      {
        key: 4,
        qn: '¿Tendré qué acudir a una oficina de la división MVD o a un proveedor externo autorizado para obtener la nueva identificación para viajar AZ Travel ID?',
        ans: 'Sí. Los solicitantes deben presentar su solicitud en persona, pero llene la solicitud antes de visitar una oficina.',
      },
      {
        key: 5,
        qn: '¿Cuánto costará la nueva identificación para viajar AZ Travel ID?',
        ans: 'El costo de una identificación para viajar AZ Travel ID es de $25 (la solicitud inicial y la renovación).'
      },
      {
        key: 6,
        qn: '¿Durante cuánto tiempo será válida mi nueva identificación para viajar AZ Travel ID?',
        ans: 'Los requerimientos federales requieren que la identificación para viajar sea válida durante un máximo de ocho años. Hay excepciones aplicables, incluyendo a los conductores de 65 años de edad ó mayores, cuyas licencias son válidas actualmente durante cinco años. Para las personas que no sean ciudadanas de los Estados Unidos, cuya presencia legal sea autorizada, la fecha de vencimiento se basará en los documentos de inmigración presentados, pero no excederá a los ocho años.'
      },
      {
        key: 7,
        qn: '¿Qué documentos necesito para solicitar una nueva identificación para viajar AZ Travel ID?',
        ans: 'Usted necesitará un documento para establecer el lugar de nacimiento o su presencia legal, un documento para verificar su número de Seguro Social, y dos documentos para establecer su residencia en Arizona.',
        ans_link_copy: ' Requerimientos para la Identificación para Viajar de Arizona, 40-5144',
        ans_link: 'https://azdot.gov/motor-vehicles/driver-services/arizona-travel-id/identification-requirements'
      },
      {
        key: 8,
        qn: '¿La nueva identificación para viajar AZ Travel ID contendrá un microprocesador de identificación por radiofrecuencia (RFID por sus siglas en inglés)?',
        ans: 'No, la ley estatal prohíbe que la identificación para viajar AZ Travel ID contenga un microprocesador RFID.'
      },
      {
        key: 9,
        qn: 'Yo tuve alguna razón para obtener una nueva licencia de manejo o identificación de Arizona y decidí no obtener la nueva identificación para viajar AZ Travel ID. Mi nueva identificación dice “No es para la identificación federal”. ¿Significa esto que no puedo abordar un avión para viajes domésticos ni entrar a edificios federales restringidos y bases militares?',
        ans: 'El Departamento de Seguridad Nacional de los Estados Unidos ha dicho que las identificaciones de los estados que ofrecen credenciales que cumplan con el Decreto Federal de IDENTIFICACIÓN REAL serán aceptadas hasta el 7 de Mayo de 2025. Incluso si una licencia de manejo o identificación dice, “NO ES PARA LA IDENTIFICACIÓN FEDERAL”, el departamento DHS ha dicho que será aceptada hasta dicha fecha.',
        paddingBottom: '150px',
      }
    ]
  },
  footer: {
    title: 'Ayúdanos a enviar este mensaje',
    copy: 'Descargar más información',
    button: 'Descargar',
    logo_src: '/img/footer-logo-es.png'
  }
};