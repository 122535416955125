import React from 'react';
import Text from '../Text/Text';
import { weights, colors, sizes } from '../../style'

const SectionTitle = ({
  marginTop = "10px",
  marginBottom = "0",
  fontWeight = weights.bold,
  color = colors.darkOrange,
  textAlign = "center",
  isMobile,
  className,
  width,
  children
}) => (
  <Text
    marginTop={marginTop}
    marginBottom={marginBottom}
    fontSize={isMobile ? sizes.size2 : sizes.xl}
    fontWeight={fontWeight}
    color={color}
    textAlign={textAlign}
    className={className}
    width={width}
  >
    {children}
  </Text>
);

export default SectionTitle;