import React from 'react';

const Text = ({ type = 'p', margin = "0", children, ...props }) => {
  const Element = type;
  return (
    <Element style={{ margin, ...props }}>
      {children}
    </Element>
  );
};

export default Text;