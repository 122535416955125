import React from 'react';
import Flex from '../Flex/Flex';
import { colors } from '../../style';

const Card = ({
  height = "330px",
  width = "80%",
  backgroundColor = colors.white,
  marginTop="75px",
  marginLeft="40px",
  marginRight="40px",
  children,
  alignItems = "center",
  justifyContent = "space-between",
  zIndex,
  maxWidth,
  position,
  className = '',
}) => (
  <Flex
    height={height}
    width={width}
    maxWidth={maxWidth}
    backgroundColor={backgroundColor}
    marginTop={marginTop}
    marginLeft={marginLeft}
    marginRight={marginRight}
    flexDirection="column"
    alignItems={alignItems}
    justifyContent={justifyContent}
    zIndex={zIndex}
    position={position}
    className={className}
  >
    {children}
  </Flex>
);

export default Card;