import React from 'react';
import Flex from '../Flex/Flex';
import Link from '../Link/Link';
import Image from '../Image/Image';
import { colors } from '../../style';

const Footer = ({ isMobile, ...props }) => (
  <Flex
    flexDirection={isMobile && 'column'}
    marginTop={isMobile ? '-90px' : '-140px'}
    zIndex="1003"
    position="relative"
    padding={isMobile ? "40px 0" : "3% 16%"}
    width="100%"
    alignItems="center"
    className="footer-container"
    justifyContent={!isMobile && 'center'}
    boxSizing="border-box"
    backgroundColor={colors.darkOrange}
    id="footer"
  >
    <Flex flexDirection={isMobile ? 'column' : 'row' } alignItems="center" justifyContent='space-evenly' alignContent='center' width='100%'>
      <Image src={props.srcLogo} width="230px" />
      <Flex margin={isMobile ? '20px 0' : 0} alignItems="center">
        <Link href="https://www.facebook.com/AZDOT/" target="_blank">
          <Image src="/img/fb-logo@1x.png" width="50px" />
        </Link>
        <Link href="https://twitter.com/ArizonaDOT" target="_blank">
          <Image src="/img/tw-logo@1x.png" width="32px" />
        </Link>
      </Flex>
      <Image src="/img/ADOTLogo_Mobile_Footer.png" width={isMobile ? '100px' : '230px'}/>
    </Flex>
  </Flex>
);

export default Footer;