import React from 'react';
import Flex from '../Flex/Flex';
import Text from '../Text/Text';
import Image from '../Image/Image';

import { colors, weights } from '../../style';

const CheckText = ({ children, margin }) => (
  <Flex alignItems="center" margin={margin}>
    <Image src="/img/check@1x.png" />
    <Text color={colors.darkBlue} fontWeight={weights.bold} marginLeft="10px">{children}</Text>
  </Flex>
);

export default CheckText;