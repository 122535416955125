import React from 'react';
import Flex from '../Flex/Flex';

import { colors } from '../../style';
import { paddingXMobile, paddingXDesktop } from '../../utils/utils';

const Section = ({
  id,
  height,
  isMobile,
  marginTop = '0',
  padding,
  skew = 12,
  width = '100%',
  backgroundColor = colors.darkBlue,
  backgroundImage = '',
  children,
  zIndex,
  noSkew = false,
  positive,
  className = '',
}) => (
  <Flex
    height={height}
    width={width}
    padding={padding ? padding : `120px ${isMobile ? paddingXMobile : paddingXDesktop}`}
    marginTop={marginTop}
    boxSizing="border-box"
    backgroundColor={backgroundColor}
    backgroundImage={backgroundImage}
    backgroundSize="cover"
    transform={!noSkew && `skew(0deg, ${positive ? `${skew}deg` : `-${skew}deg`})`}
    zIndex={zIndex}
    className={className}
  >
    <Flex
      id={id}
      height="100%"
      width="100%"
      transform={!noSkew && `skew(0deg, ${positive ? `-${skew}deg` : `${skew}deg`})`}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Flex>
  </Flex>
);

export default Section;
