export const sizes = {
  xl: '42px',
  size1: '30px',
  size2: '26px',
  size3: '20px',
  size4: '19px',
  size5: '18px',
  size6: '16px',
  size7: '12px',
  size8: '10px',
  size9: '8px',
};

export const weights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};