import React from 'react';
import Card from './Card';
import Image from '../Image/Image';
import Text from '../Text/Text';
import Flex from '../Flex/Flex';
import CheckText from '../CheckText/CheckText';

import { colors, sizes, weights } from '../../style';

const ChecksCard = ({ image, title, subtitle, checkSections, imageWidth = '130px', zIndex, position, className, height }) => (
  <Card marginTop="45px" justifyContent="flex-start" zIndex={zIndex} className={className} position={position} height={height}>
    <Image src={image} width={imageWidth} paddingTop="30px" />
    <Text
      fontSize={sizes.size2}
      color={colors.darkOrange}
      fontWeight={weights.bold}
      marginTop="20px"
      marginBottom="20px"
      textAlign="center"
      padding="0 10px"
      width="90%"
    >
      {title}
    </Text>
    {subtitle &&
      <Text fontSize={sizes.size6} color={colors.darkBlue} fontWeight={weights.light} marginTop="10px">
        {subtitle}
      </Text>
    }

    {checkSections &&
      <Flex flexDirection="column" marginTop="10px" padding="30px">
        {checkSections.map((item, index) => <CheckText key={index} margin="2px 0">{item}</CheckText>)}
      </Flex>}

  </Card>
);

export default ChecksCard;