import React from 'react';
import { sizes, weights } from '../../style';

const RadioBtn = ({
  className,
  width = '200px',
  fontSize = sizes.size6,
  fontFamily = 'Exo 2',
  fontWeight = weights.medium,
  border = 'none',
  checkDefault,
  children,
  onClick = () => {},
  ...props
}) => (
  <div className="radio-btns--wrapper">
    <label
      style={{
        width,
        fontSize,
        fontFamily,
        fontWeight,
        cursor: 'pointer',
        ...props
      }}
    >
      <input
        type="radio"
        value={children}
        onClick={onClick}
        checked={checkDefault ? "checked" : ''}
        name="radio"
      />
      {children}
      <span className="radio-btn"></span>
    </label>
  </div>
);

export default RadioBtn;