export const redirectToHTTPS = () => {
  if (window.location.protocol !== 'https:') {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
  }
}

export const isMobile = () => {
  if (typeof window !== 'undefined') {
    var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  }
  return w <= 1023;
};

export const paddingXMobile = "20px";
export const paddingXDesktop = "80px"