import React, { useState, useEffect, useCallback } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ScrollLock from 'react-scrolllock';
import Flex from '../components/Flex/Flex';
import Text from '../components/Text/Text';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import SectionParagraph from '../components/SectionParagraph/SectionParagraph';
import Button from '../components/Button/Button';
import RadioBtn from '../components/RadioBtn/RadioBtn';
import Link from '../components/Link/Link';
import Collapse from '../components/Collapse/Collapse';
import Image from '../components/Image/Image';
import Card from '../components/Card/Card';
import ChecksCard from '../components/Card/ChecksCard';
import Footer from '../components/Footer/Footer';
import Section from '../components/Section/Section';
import HamburgerMenu from '../components/HamburgerMenu/HamburgerMenu';
import DesktopHeaderLink from '../components/DesktopHeaderLink/DesktopHeaderLink';
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from 'react-switch-lang';
import en from '../i18n/en.js';
import es from '../i18n/es.js';

import './app.scss';

import { colors, sizes, weights } from '../style';
import { redirectToHTTPS, isMobile, paddingXDesktop } from '../utils/utils';

const App = ({ t = this.props }) => {
  const [checkDefault, setCheckDefault] = useState('default');
  const [isMobileState, setIsMobileState] = useState(isMobile);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);
  const [isScrollLocked, setIsScrollLocked] = useState(false);
  const [lang, setLang] = useState(en);

  const setIsMobile = useCallback(() => {
    window.addEventListener('resize', setIsMobile);
    setIsMobileState(isMobile());
  }, [])

  useEffect(() => {
    redirectToHTTPS();
    setIsMobile();
    setTranslations({ en, es });
    setDefaultLanguage('en');
  }, [setIsMobile]);

  const hamburgerClickLink = (sectionName) => {
    setIsHamburgerMenuOpen(false);
    setIsScrollLocked(false);
    const element = document.getElementById(sectionName);
    element.scrollIntoView();
  }

  const toggleHamburgerMenu = () => {
    setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
    setIsScrollLocked(!isScrollLocked);
  }

  const handleSetLanguage = (key, objLang) => {
    setLanguageCookie();
    setLanguage(key);
    setLang(objLang);
  };

  return (

    <ScrollLock isActive={isScrollLocked}>
      <Flex zIndex="9999" justifyContent="flex-start" className="lang-container">
        <RadioBtn
          value="en-lang"
          paddingRight="20px"
          paddingLeft="28px"
          width="100%"
          checkDefault={checkDefault === 'default'}
          onClick={() => { handleSetLanguage('en', en); setCheckDefault('default'); }}
        >
          {t('lang.en')}
        </RadioBtn>
        <RadioBtn
          value="es-lang"
          paddingLeft="28px"
          width="100%"
          checkDefault={checkDefault === 'nodefault'}
          onClick={() => { handleSetLanguage('es', es); setCheckDefault('nodefault'); }}
        >
          {t('lang.es')}
        </RadioBtn>
      </Flex>
      <React.Fragment>
        <Image
          src="/img/bg-wing@1x.png"
          width={isMobileState ? '10%' : '30%'}
          minWidth="150px"
          maxWidth="300px"
          position="absolute"
          top="0"
          left="0"
          zIndex="-1"
        />
        <Flex marginTop={isMobileState && !isHamburgerMenuOpen && '52px'} marginBottom='50px' flexDirection="column" className="parent-wrapper">
          
          {isMobileState
            ? <Flex justifyContent="flex-end" width="50%" position="absolute" top="75px" right="20px" zIndex="1001">
              <Image src="/img/ADOTLogo_Mobile_Header.png" width={'90px'} marginRight='15px' height='19px'/>
              <Link onClick={() => toggleHamburgerMenu()}>
                <Image src="/img/hamburgerMenu.png" width="30px" />
              </Link>
            </Flex>
            : 
            <>
            <Flex zIndex="9999" className="desktop-menu" position='relative' padding={`0 ${paddingXDesktop} 0 ${paddingXDesktop}`} justifyContent="flex-end">
              <Image top='30px' right='10px' width='117px' position='relative' src='/img/ADOTLogo_Mobile_Header.png'/>
            </Flex>
            <Flex zIndex="9999" className="desktop-menu" padding={`50px ${paddingXDesktop} 0 ${paddingXDesktop}`} justifyContent="flex-end">
              <DesktopHeaderLink linkTo="#whatIs" className="hvr-bob">{t('menu_links.about')}</DesktopHeaderLink>
              <DesktopHeaderLink linkTo="#steps" className="hvr-bob">{t('menu_links.steps')}</DesktopHeaderLink>
              <DesktopHeaderLink linkTo="#requirements" className="hvr-bob">{t('menu_links.requirements')}</DesktopHeaderLink>
              <DesktopHeaderLink linkTo="#faqs" className="hvr-bob">{t('menu_links.faq')}</DesktopHeaderLink>
            </Flex></>
          }
          <Section id="home" className="home-section" isMobile={isMobileState} zIndex={!isMobileState && 1000} skew={isMobileState ? 14 : 12} backgroundImage="url('/img/map_shapes_bg2.png')">
            <Image src="/img/hero_logo@1x.png" width={isMobileState ? '200px' : '350px'} />
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              marginTop="10px"
              padding="0 20px"
              boxSizing="border-box"
              maxWidth="500px"
            >
              <Flex height="3px" marginRight="15px" width="100%" backgroundColor={colors.darkOrange} />
              <Image className="star-hero-img" src="/img/starheromobile@1x.png" />
              <Flex height="3px" width="100%" marginLeft="15px" backgroundColor={colors.darkOrange} />
            </Flex>
            <SectionTitle isMobile={isMobileState} marginTop="5px" color={colors.darkBlue}>
              {t('hero.title')}
            </SectionTitle>
            <Flex flexDirection="column" alignItems="center" padding="0 30px" boxSizing="border-box" marginTop="30px">
              <SectionParagraph maxWidth="800px">
                {t('hero.main_description')}
              </SectionParagraph>
              <SectionParagraph maxWidth="800px">
                {t('hero.secundary_description')}
              </SectionParagraph>
              <AnchorLink
                className="main-link hvr-sweep-to-right"
                href="#steps"
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                {t('hero.button')}
              </AnchorLink>
            </Flex>
          </Section>
          <Section skew={isMobileState && 14} id="whatIs" isMobile={isMobileState} zIndex={isMobileState ? 1001 : 999} noSkew={!isMobileState && true} height={!isMobileState && '700px'} marginTop={!isMobileState && '-210px'} className={isHamburgerMenuOpen ? 'hide-section' : 'show-section' && 'about-section'}>
            <Flex flexDirection={isMobileState && 'column'} alignItems={isMobileState ? 'center' : 'flex-end'} justifyContent={!isMobileState && 'flex-end'} width="100%">
              <Flex flexDirection="column" className="what-is-section" alignItems={!isMobileState && 'flex-end'} paddingRight={!isMobileState && '100px'} paddingTop={!isMobileState && '80px'}>
                <SectionTitle isMobile={isMobileState} color={colors.orange} marginBottom="12px" marginTop={!isMobileState ? '150px' : ''}>{t('about.title')}</SectionTitle>
                <SectionParagraph zIndex={1} maxWidth="800px" color={colors.white} textAlign={!isMobileState ? 'right' : 'center'}>
                  {t('about.copy')} 
                </SectionParagraph>
                <Link className="hvr-bubble-float-right" marginTop="25px" fontSize={sizes.size4} href="https://www.dhs.gov/real-id-public-faqs" target="_blank">
                  <Text fontWeight={weights.bold} textAlign="center" textDecoration="underline" color={colors.skyBlue}>
                    {t('about.link')}
                  </Text>
                </Link>
              </Flex>
              {isMobileState && <Image src="/img/License@1x.png" width="75%" maxWidth="500px" marginTop="40px" />}
            </Flex>
            {!isMobileState && <Flex height="400px" className="blue-divider" backgroundColor={colors.darkBlue} width="100%" position="absolute" />}
          </Section>
          <Section id="steps" padding={isMobileState ? '120px 24px' : '220px 24px'} className="cards-container" isMobile={isMobileState} backgroundColor={colors.white} zIndex="1000" marginTop={isMobileState && '-70px'} noSkew={isMobileState && true} positive={!isMobileState && true}>
            {!isMobileState && <Flex className="driver-license"><Image src="/img/License@1x.png" width="75%" maxHeight="350px" minWidth="450px" marginTop="40px" /></Flex>}
            <Flex flexDirection="column" id="steps" alignItems="center" marginTop={!isMobileState && '70px'} width="100%">
              <SectionTitle isMobile={isMobileState} width='60%'>{t('steps.title')}</SectionTitle>
              <Flex
                flexDirection={isMobileState && 'column'}
                alignItems="center"
                justifyContent="center"
                width="100%"
                maxWidth="1100px"
              >
                {/*
                  This is Probably going to come back after the COVID

                 <Card className="image-card" maxWidth="300px" position="relative">
                  <Image className="image-star" src="/img/Star1@1x.png" width="100px" position="absolute" marginTop="-55px" borderRadius="50%"/>
                  <Image src="/img/icon01@1x.png" width="135px" marginTop="65px" />
                  <Button className="hvr-sweep-to-right" padding="0 30px" width="100%" height="85px" onClick={() => window.location.href = "https://apps.azdot.gov/mvd/Application/ApplicationForm.aspx"}>
                    {t('steps.step_one')}
                  </Button>
                </Card>
                <Card className="image-card" maxWidth="300px" position="relative">
                  <Image className="image-star" src="/img/Star2@1x.png" width="100px" position="absolute" marginTop="-55px" borderRadius="50%"/>
                  <Image src="/img/icon02@1x.png" width="135px" marginTop="65px" />
                  <Button className="hvr-sweep-to-right" padding="0 30px" width="100%" height="85px" onClick={() => window.location.href = "https://servicearizona.com/webapp/moa/findService?message=nosession"}>
                    {t('steps.step_two')}
                  </Button>
                </Card>
                */}

                <Card className="image-card" maxWidth="300px" position="relative">
                  <Image className="image-star" src="/img/Star1@1x.png" width="100px" position="absolute" marginTop="-55px" borderRadius="50%"/>
                  <Image src="/img/icon02@1x.png" width="135px" marginTop="65px" />
                  <Button 
                    onClick={() => window.location.href = "https://azmvdnow.gov/home"}
                    className="hvr-sweep-to-right" padding="0 30px" width="100%" height="85px">
                    {t('steps.step_covid')}
                  </Button>
                </Card>

                <SectionTitle isMobile={isMobileState} marginTop="25px" color={colors.darkBlue}>{t('steps.or')}</SectionTitle>
                <Card className="image-card" maxWidth="300px" position="relative">
                  <Image className="image-star" src="/img/Star2@1x.png" width="100px" position="absolute" marginTop="-55px" borderRadius="50%" />
                  <Image src="/img/icon03@1x.png" width="135px" marginTop="65px" />
                  <Button className="hvr-sweep-to-right" padding="0 30px" width="100%" height="85px" onClick={() => window.location.href = "https://azdot.gov/node/4881"}>
                    {t('steps.step_three')}
                  </Button>
                </Card>
              </Flex>
            </Flex>
          </Section>
          <Section
            id="requirements"
            backgroundColor="transparent"
            isMobile={isMobileState}
            zIndex={isMobileState ? '-2' : '1001'}
            noSkew={!isMobileState && true}
            marginTop="-110px"
          >
            <Flex flexDirection="column" alignItems="center" width="100%" zIndex="9999" position="relative">
              <SectionTitle isMobile={isMobileState} width={isMobileState ? '60%' : '80%'}>{t('requirements.title')}</SectionTitle>
              <SectionParagraph maxWidth="800px" marginTop="15px">
                {t('requirements.copy')}
              </SectionParagraph>
              <Flex
                flexDirection={isMobileState && 'column'}
                alignItems="center"
                justifyContent="space-between"
                className="checks-container"
                width="100%"
                maxWidth="1300px"
                paddingBottom={!isMobileState && '50px'}
              >
                <ChecksCard
                  className="checks-card"
                  height={isMobileState ? 'auto' : '380px'}
                  image="/img/card01@1x.png"
                  title={t('requirements.card_title_1')}
                  subtitle={t('requirements.choose_one')}
                  checkSections={lang.requirements.card_features_1}
                />
                <ChecksCard
                  className="checks-card"
                  height={isMobileState ? 'auto' : '380px'}
                  image="/img/card02@1x.png"
                  title={t('requirements.card_title_2')}
                  // subtitle={t('requirements.choose_one')}
                  // checkSections={lang.requirements.card_features_2}
                />
                <ChecksCard
                  className="checks-card"
                  height={isMobileState ? 'auto' : '380px'}
                  paddingTop="30px"
                  image="/img/card03@1x.png"
                  imageWidth="60px"
                  title={t('requirements.card_title_3')}
                  subtitle={t('requirements.choose_two')}
                  checkSections={lang.requirements.card_features_3}
                />
              </Flex>
            </Flex>
          </Section>
          {!isMobileState && <Section marginTop="-230px" height="300px" positive />}
          <Section
            id="faqs"
            isMobile={isMobileState}
            marginTop={isMobileState ? '-30px' : '-200px'}
            skew={!isMobileState && 6.5}
            positive
          >
            <Flex flexDirection="column" maxWidth="800px">
              <SectionTitle isMobile={isMobileState} color={colors.orange} marginBottom="30px">
                {t('faqs.title')}
              </SectionTitle>
              {lang.faqs.content.map((item) => (
                <Collapse
                  title={item.qn}
                  content={item.ans}
                  moreText={item.key === 7 ? true : false}
                  faqlink={item.ans_link}
                  contentMore={item.ans_link_copy}
                ></Collapse>
              ))}
            </Flex>
          </Section>
        </Flex>
        <Footer isMobile={isMobileState} title={t('footer.title')} content={t('footer.copy')} download={t('footer.button')} srcLogo={t('footer.logo_src')} />
        {isHamburgerMenuOpen && <HamburgerMenu about={t('menu_links.about')} steps={t('menu_links.steps')} requirements={t('menu_links.requirements')} faq={t('menu_links.faq')} srcLogo={t('footer.logo_src')} onLinkClick={(sectionName) => hamburgerClickLink(sectionName)} />}
      </React.Fragment>
    </ScrollLock>
  );
};

export default translate(App);
