import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Link from '../Link/Link';

import { colors, weights, sizes } from '../../style';

const DesktopHeaderLink = ({ children, linkTo, className }) => (
  <Link color={colors.darkBlue} fontWeight={weights.bold} margin="0 20px" fontSize={sizes.size5}>
    <AnchorLink className={className} href={linkTo} style={{ color: 'inherit', textDecoration: 'none' }}>{children}</AnchorLink>
  </Link>
);

export default DesktopHeaderLink;