import React, { useState, useRef } from 'react';
import Flex from '../Flex/Flex';
import Text from '../Text/Text';
import Link from '../Link/Link';

import { colors, weights } from '../../style';
import './collapse.scss';

const Collapse = (props) => {
  const [active, setActive] = useState("");
  const [height, setHeight] = useState("0");

  const content = useRef(null);

  function toggleAccordion() {
    setActive(active === "" ? "active" : "");
    setHeight(
      active === "active" ? "0" : `${content.current.scrollHeight}px`
    );
  }

  return (
    <Flex flexDirection="column" margin="20px 0" cursor="pointer">
      <Flex justifyContent="space-between" color={colors.white} className={`accordion ${active}`} onClick={toggleAccordion}>
        <Text fontWeight={weights.bold} maxWidth="85%">{props.title}</Text>
        <Text>{active ? '-' : '+'}</Text>
      </Flex>
      <div
        ref={content}
        style={{ maxHeight: `${height}` }}
        className="accordion__content"
      >
        <Text 
          margin="20px 0"
          paddingLeft="20px"
          color={colors.skyBlue}
          fontWeight={weights.bold}
        > 
          {props.content}
          {props.moreText &&
            <Link  zIndex={1} color={colors.skyBlue} fontWeight={weights.bold} href={props.faqlink} target="_blank">
              {props.contentMore}
            </Link>
          }
        </Text>
      </div>
    </Flex>
  );
}

export default Collapse;