import React from 'react';
import { colors, sizes, weights } from '../../style';

const Button = ({
  className,
  width = '200px',
  height = '50px',
  fontSize = sizes.size3,
  fontFamily = 'Exo 2',
  fontWeight = weights.bold,
  backgroundColor = colors.orange,
  border = 'none',
  color = colors.white,
  children,
  onClick = () => {},
  ...props
}) => (
  <button
    style={{
      width,
      height,
      fontSize,
      fontFamily,
      fontWeight,
      backgroundColor,
      border,
      color,
      cursor: 'pointer',
      ...props
    }}
    onClick={onClick}
    className={className}
  >
    {children}
  </button>
);

export default Button;