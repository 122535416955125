import React from 'react';
import Text from '../Text/Text';

import { colors, sizes, weights } from '../../style';

const SectionParagraph = ({ children, maxWidth, marginTop, color = colors.darkBlue, textAlign = 'center', zIndex }) => (
  <Text zIndex={zIndex} color={color} textAlign={textAlign} maxWidth={maxWidth} marginTop={marginTop} fontSize={sizes.size5} fontWeight={weights.medium}>
    {children}
  </Text>
);

export default SectionParagraph