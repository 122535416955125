module.exports = 
{
  menu_links: {
    about: 'About Us',
    steps: 'Steps',
    requirements: 'Requirements',
    faq: 'FAQ'
  },
  lang: {
    en: 'English',
    es: 'Spanish'
  },
  hero: {
    title: 'Will Your License Fly?',
    main_description: 'If you need to travel, you need to take a trip to your local MVD office or authorized third-party provider to get the new AZ Travel ID.',
    secundary_description: 'The AZ Travel ID is part of a nationwide TSA security change starting May 7, 2025, requiring an AZ Travel ID or another form of approved identification for domestic travel.',
    button: 'Get your AZ Travel ID'
  },
  about: {
    title: 'What is the AZ Travel ID?',
    copy: 'The Arizona Travel ID is the credential that complies with the federal REAL ID Act of 2005. It is available as both a driver license and identification card. There is a star embedded in the card to show that the cardholder has provided added proof of identification to ensure the license or ID meets strict federal requirements.',
    link: 'Learn More About The Real ID Act >>'
  },
  steps: {
    title: 'How to get your AZ Travel ID',
    or: 'OR',
    step_one: 'Apply',
    step_two: 'Schedule MVD Appointment',
    step_three: 'View Third-Party Providers',
    step_covid: 'Schedule at AZMVDNOW.gov'
  },
  requirements: {
    title: 'AZ Travel ID Document Requirements',
    copy: 'You must provide your social security number as well as documents to establish your identity and place of residence. These documents must be in English and original or certified copies from the issuing agency.',
    choose_one: 'Choose one of these:',
    choose_two: 'Choose two of these:',
    card_title_1: 'Proof of Identity',
    card_features_1: [
      'Birth Certificate',
      'U.S. Passport',
      'Passport Card'
    ],
    card_title_2: 'Social Security Number',
    card_features_2: [
      'Social Security Card',
      'W-2 Form'
    ],
    card_title_3: 'Proof of Arizona Residency',
    card_features_3: [
      'Utility Bills',
      'Credit Card Statement',
      'Bank Statement',
      'Insurance Policy'
    ]
  },
  faqs: {
    title: 'Frequently Asked Questions',
    content: [
      {
        key: 1,
        qn: 'Am I Required to obtain an AZ Travel ID?',
        ans: 'No. Arizona residents have an option to purchase a Travel ID.',
        open: false
      },
      {
        key: 2,
        qn: 'Can I use my current Arizona driver license or identification card to board an aircraft?',ans: 'Yes. The U.S. Department of Homeland Security has said IDs from states offering credentials complying with the federal REAL ID Act will be accepted until May 7, 2025.',
        open: false
      },
      {
        key: 3,
        qn: 'Can I use my current Arizona driver license or identification card to enter restricted federal buildings and military bases?',
        ans: 'Yes. The U.S. Department of Homeland Security has said IDs from states offering credentials complying with the federal REAL ID Act will be accepted until May 7, 2025.',
        open: false
      },
      {
        key: 4,
        qn: 'Will I have to go to an MVD office or an authorized third-party provider to get the new AZ Travel ID?',
        ans: 'Yes. Applicants must apply in person, but fill out the application before visiting an office.',
      },
      {
        key: 5,
        qn: 'How much will the new AZ Travel ID cost?',
        ans: 'The cost of an AZ Travel ID is $25 (initial application and renewal).'
      },
      {
        key: 6,
        qn: 'How long will my new AZ Travel ID be valid?',
        ans: 'Federal requirements require the Travel ID to be valid for a maximum of eight years. Exceptions apply, including drivers age 65 or older whose licenses are currently valid for five years. For non-U.S. citizens who have authorized legal presence the expiration date will be based off the immigration documents presented, not to exceed eight years.'
      },
      {
        key: 7,
        qn: 'What documents do I need to apply for the new AZ Travel ID?',
        ans: `You will need one document to establish birth or legal presence and two documents to establish residency in Arizona.`,
        ans_link_copy: ' Arizona Travel ID Identification',
        ans_link: 'https://azdot.gov/motor-vehicles/driver-services/arizona-travel-id/identification-requirements'
      },
      {
        key: 8,
        qn: 'Will the new AZ Travel ID contain a radio frequency ID (RFID) chip?',
        ans: 'No, state law prohibits the AZ Travel ID from containing a RFID chip.'
      },
      {
        key: 9,
        qn: 'I had reason to get a new Arizona driver license or ID and decided against getting the new AZ Travel ID. My new ID says “Not for federal identification.” Does this mean I can’t board an aircraft for domestic travel or enter restricted federal buildings and military bases?',
        ans: 'The U.S. Department of Homeland Security has said IDs from states offering credentials complying with the federal REAL ID Act will be accepted until May 7, 2025. Even if a driver license or ID says, “NOT FOR FEDERAL IDENTIFICATION," DHS has said it will be accepted through that date.',
        paddingBottom: '150px'
      }
    ]
  },
  footer: {
    title: 'Help us spread the word!',
    copy: 'Download the AZ Travel ID Toolkit.',
    button: 'Download',
    logo_src: '/img/footer-logo-en.png'
  }
};